<!--  -->
<template>
  <div class="details-all-mob">
    <div class="return-mob"><img class="img" src="../../assets/mobreturn.png" @click="returnRoute"/></div>
    <div class="details-all">
      <div class="title">{{newsdetails.title}}</div>
      <div class="timedetails">
        <div class="time"><img class="img" src="../../assets/icon_date.png"/>{{newsdetails.create_time}}</div>
        <div class="share"><img class="img" src="../../assets/icon_share.png"/>分享</div>
      </div>
      <div class="details-centent" v-html="newsdetails.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      newsdetails: {}
    }
  },
  methods: {
    // 返回上个路由
    returnRoute () {
      this.$router.go(-1)
    },
    // 获取详情
    getViewdetails (e) {
      this.isdetail = true
      this.$axios.post('api/news/getnewsbyid', {
        id: this.$route.query.id
      }).then(res => {
        this.newsdetails = res.data.data
        console.log(this.newsdetails)
      })
    }
  },
  created () {},
  mounted () {
    this.getViewdetails()
    console.log(this.newsdetails)
  }
}
</script>
<style scoped lang='less'>
.details-all-mob{
  height:100vh;
  background: #F7F8FA;
  .details-all{
    margin: auto;
    padding: 15px;
    background: #F7F8FA;
    .title{
      font-size: 0.93rem;
      font-weight: 600;
      color: #222222;
      margin-bottom: 1.25rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: center;
    }
    .timedetails{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .time,.share{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.81rem;
        color: #999999;
        .img{
          width:0.93rem;
          height:0.93rem;
          margin-right: 3px;
        }
      }
      .share{
        font-size: 0.81rem;
        font-weight: 400;
        color: #0DC102;
      }
    }
  .details-centent{
    overflow: hidden;
    p{
      width:100%;
      img{
        width:100%
      }
    }
  }
  .return{
    font-size: 22px;
    font-weight: 500;
    color: #019EFA;
    text-align: right;
    margin-top: 65px;
    margin-bottom: 110px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img{
      width: 25px;
    }
  }
}
  .return-mob{
    height: 44px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    img{
      width:1.5rem
    }
  }
}
</style>

<style lang="less">
.details-centent /deep/ p {
    width:100%;
}
.details-centent /deep/ img {
    width:100%;
}
</style>
